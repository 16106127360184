





















import { Component, Vue } from 'vue-property-decorator'
// import { Component, Vue } from 'vue-property-decorator'
// import HelloWorld from '@/components/HelloWorld.vue' // @ is an alias to /src

// @Component({
//   components: {
//     HelloWorld
//   }
// })

import episodes from '../data/pawpatrol.json'

@Component
export default class HomeView extends Vue {
  episodes = episodes
  search = ''

  get filteredList () {
    if (this.search === '') {
      return this.episodes
    } else {
      const list = []
      let last: any
      for (let i = 0; i < this.episodes.length; i++) {
        const episode = { ...episodes[i] as any }
        const title = `${episode.title} ${episode.titleOrg}`.toLowerCase()
        if (title.indexOf(this.search.toLowerCase()) >= 0) {
          if (episode.episode.indexOf('b') >= 0) episode.last = last
          list.push(episode)
        }
        last = episode
      }
      return list
    }
  }

  formatIdf (episode:any) {
    return `s${episode.season}e${episode.episode}`
  }

  onNetflix (episode:any) {
    return [1, 5, 6].includes(+episode.season)
  }
}
